<template>

  <Card class="p-col">
    <template #title>
      <div class="p-d-flex p-jc-between">
        <div>
          <h3 class="p-m-0"><strong>{{ _t('label_system_tables_columns') }}</strong></h3>
        </div>
        <div>
          <Button v-if="canBeEdited" v-tooltip.bottom="_t('Cancel')"
                  class="p-button-rounded p-button-danger p-button-text" icon="pi pi-times"
                  @click="canBeEdited = false"/>
          <Button
              v-tooltip.bottom="canBeEdited ? _t('Save') :_t('Edit')"
              :class="canBeEdited ? 'p-button-rounded p-button-text p-button-success' : 'p-button-rounded p-button-text p-button-secondary' "
              :icon="canBeEdited ? 'pi pi-check' : 'pi pi-pencil'"
              @click="processEditing"
          />
        </div>
      </div>
    </template>
    <template #content>
      <TabPanel
          :header="_t('label_view')"
      >
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <Card v-for="(element,key) in orgColsData"
                :key="key"
                class="style p-col"
                style="padding: 5px 20px; min-width: 410px; max-width: 410px;">
            <template #title>
              <h3>
                <strong>{{ _t(getTitle(key)) }}</strong>
              </h3>
            </template>
            <template #content>
              <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                <div v-for="(item) in element" :key="item.value + item.label" class="field-checkbox"
                     style="width: 160px; margin: 5px 0;">
                  <Checkbox v-model="item.checked"
                            :disabled="!canBeEdited" :inputId="item.value"
                            :name="item.value" :value="item.value"/>
                  <label :for="item.value" style="margin-left: 15px;">{{ _t(`${item.label}`) }}</label>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>
    </template>
  </Card>

</template>
<script>


import {initialColsData} from "@/constants/constants";
import {getOrganizationColumns, getUserColumns, setOrganizationColumns, setUserColumns} from "@/api/uiColumns";
import router from "@/router";

export default {
  name: 'test',
  data: () => ({
    canBeEdited: false,
    orgColsData: [],
    userColsData: [],
  }),
  mounted() {
    if (localStorage.getItem('GCOP') !== 'true') {
      router.push('/')
    }

    getUserColumns().then((res) => {
      this.userColsData = res.data
    })
    getOrganizationColumns()
        .then((res) => {
          if (
              !res.data ||
              Object.values(res.data)[0] === [] ||
              typeof res.data !== "object"
          ) {
            this.orgColsData = initialColsData;
          } else {
            if (Object.keys(res.data).length !== Object.keys(initialColsData).length) {
              let newTable = [];
              Object.keys(initialColsData).forEach((col) => {
                if (!Object.keys(res.data).includes(col)) {
                  newTable.push(col)
                }
              })

              if (newTable.length) {
                let newInitColumns = {...res.data}
                newTable.map((el) => newInitColumns[el] = initialColsData[el].map((elem) => ({...elem, checked: []})))

                this.orgColsData = newInitColumns
                this.saveOrganizationColumnsView()
              } else {
                const missingKey = []
                Object.keys(res.data).forEach((el) => {
                  if (!Object.keys(initialColsData).includes(el)) {
                    missingKey.push(el)
                  }
                })

                if (missingKey.length) {
                  let newColumns = {...res.data}
                  missingKey.map((el) => delete newColumns[el])

                  this.orgColsData = newColumns
                  this.saveOrganizationColumnsView()
                  window.location.reload()
                }
                this.orgColsData = res.data
              }
            } else this.orgColsData = res.data;
          }
        })
        .catch((err) => console.log("error", err));
  },
  methods: {
    processEditing() {
      if (this.canBeEdited === true) {
        this.saveOrganizationColumnsView()
      }
      this.canBeEdited = !this.canBeEdited
    },
    saveOrganizationColumnsView() {
      setOrganizationColumns(this.orgColsData)
          .then((res) => {
            const keys = Object.keys(res.data)

            let newUserColumns = {}

            keys.forEach((key) => {
              newUserColumns[key] = this.userColsData[key]?.map((el, idx) => JSON.stringify(res.data[key][idx]?.checked) === JSON.stringify([]) ? {
                ...el,
                checked: []
              } : el)
            })

            setUserColumns(newUserColumns)
          })
    },
    getTitle(title) {
      if (title === 'alarmParticipantGroupsItems') return 'PARTICIPANT_GROUP'
      if (title === 'teamMediaSchedulesItems') return 'PARTICIPANT_ROTATIONAL_CAL'
      if (title === 'standbySchedulesItems') return 'label_ap_rotationals'
      if (title === 'alarmParticipantPlansItems') return 'label_alarm_plans'
      if (title === 'alarmParticipantsItems') return 'label_participants'
      if (title === 'alarmPointItems') return 'menu.alarm_definition.new'
      if (title === 'alarmTableItems') return 'label_alarmtable'
    },
  },
}
</script>

<style scoped>

</style>
